<!-- A设备管理-巡检管理-巡检标准 -->
<template>
    <div class="page_container height_100">
            <div class="box_head1">
            <span>点检标准</span>
                <div class="flex align_center mg_left_50 fn_size14">
                <div class="">点检标准类型：</div>
                <el-select v-model="inspectionStandardTypeValue" placeholder="请选择" class="width_150" clearable>
                    <el-option v-for="item in inspectionStandardType" :key="item.value" :label="item.description"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="mg_left_20">记录时间：</div>
                <!-- <el-input v-model="input" class="width_150"></el-input> -->
                <el-date-picker  value-format="timestamp" v-model="inspectionTimeValue" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" class="mg_left_10" :picker-options="pickerOptions">
                </el-date-picker>
                <el-button type="primary" class="mg_left_10" size="medium"
                    @click="query">查询
                </el-button>
                <!-- <div class="mg_left_20">快速查询：</div>
                <el-input v-model="input" class="width_150"></el-input> -->
            </div>
            </div>
        <div class="bgc_FFFFFF bgc_FFFFFF_height pdl30 pdr30 ">
            <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline"
                        class="width_140 bgc_03CABE buttonBorder" @click="gotoAddRepair"
                        style="background-color:#03CABE ;">添加</el-button>
                </div>
            </div>
                <el-table :data="inspectionStandardList" tooltip-effect="dark" style="width: 100%" class="mg_top_20"
                    height="80%" :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="index" label="序号" width="80"></el-table-column>
                    <el-table-column prop="inspStdID" label="点检标准编号" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width=""></el-table-column> -->
                    <el-table-column prop="InspStdType_name" label="点检标准类型" width=""></el-table-column>
                    <el-table-column prop="inspName" label="点检标准名称" width=""></el-table-column>
                    <el-table-column prop="deviceID" label="设备编号" width=""></el-table-column>
                    <el-table-column prop="inspTool" label="点检工具" width=""></el-table-column>
                    <el-table-column prop="inspTarget" label="点检目的" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="inspName" label="点检标准名称" width="" show-overflow-tooltip></el-table-column> -->
                    <!-- <el-table-column prop="oprPerson" label="记录人" width=""></el-table-column> -->
                    <!-- <el-table-column prop="oprTime" label="记录时间" width="150" :formatter="formatDate"></el-table-column> -->
                    <!-- <el-table-column prop="remarks" label="备注" width="" show-overflow-tooltip></el-table-column> -->

                    <!-- <el-table-column prop="address" label="管理图片" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;">管理</span>
                            </el-button>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="详情">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoFaultDetails(scope.row.ID)">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="editTableData(scope.row.ID)">修改</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delTableData(scope.row.ID)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>

                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize" class="mg_top_10"
                :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            <!-- 页码 -->
            
            <!-- <div class="flex justify_between align_start">
                <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->
            <!-- <div class="bgc_E2F0FE flex align_end mg_top_24 height_64">
                <div class="tab_box mg_left_30 " :class="tabIndex==0?'tab_box_active':''" @click="handleChange(0)">巡检项明细
                </div>
            </div> -->
            <!-- <div class="mg_top_22">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" @selection-change="handleSelectionChange" max-height="500"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column prop="date" label="检验项目" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="date" label="记录方式" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="参考值" width=""></el-table-column>
                    <el-table-column prop="name" label="上限" width=""></el-table-column>
                    <el-table-column prop="name" label="下限" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="address" label="标准图片" show-overflow-tooltip>
                    </el-table-column>
                </el-table>
            </div> -->
            <!-- 页码 -->
            <!-- <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination> -->
            <!-- <div class="flex justify_between align_start">
                <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->
        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        inspectionStandardQueryLimit,
        inspectionStandardTypeQuery,
        inspectionStandardDelete
    } from "@/api/api_deviceManagement";
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                imageUrl: '',
                tabIndex: 0,
                value: '',
                input: '',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                tableData: [],
                multipleSelection: [],
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                inspectionStandardList: [],
                inspectionStandardTypeValue: '',
                inspectionStandardType: [],
                inspectionTimeValue: null,
                deleteNum: '',
                userID: ''
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            this.inspectionStandardQueryLimit();
            this.inspectionStandardTypeQuery()
        },
        methods: {

            async inspectionStandardQueryLimit() {
                var param = {
                    // ID: this.detailID
                    userID: this.userID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                // console.log(this.inspectionStandardTypeValue=='')
                if (this.inspectionStandardTypeValue != null && this.inspectionStandardTypeValue.toString() != "") {
                    param.InspStdType = this.inspectionStandardTypeValue;
                }
                if (this.inspectionTimeValue != null) {
                    param.oprStartTime = this.inspectionTimeValue[0];
                    param.oprEndTime = this.inspectionTimeValue[1];
                }
                const selectRes = await inspectionStandardQueryLimit(param);
                this.inspectionStandardList = selectRes.rows
                this.pageQuantity = selectRes.pageQuantity;
            },
            query() {
                this.currentPage = 1;
                this.inspectionStandardQueryLimit();
            },
            // 点检标准类型
            async inspectionStandardTypeQuery() {
                const selectRes = await inspectionStandardTypeQuery();
                this.inspectionStandardType = selectRes.rows
            },
            //点击页码触发分页查询
            handleCurrentChange(val) {
                this.currentPage = val;
                this.inspectionStandardQueryLimit(); //查询table数据的方法
            },
            handleSelectionChange(val) {
                // console.log(val)
                this.multipleSelection = val;
            },
            handleChange(id) {
                this.tabIndex = id;
            },
            //添加
            gotoAddRepair() {
                this.$router.push({
                    path: '/deviceManagement/patrolManagement/patrolStandard/addPatrolStandard',
                })
            },
            // 修改
            editTableData(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/patrolManagement/patrolStandard/modifyPatrolStandard',
                })
            },
            //详细
            gotoFaultDetails(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/patrolManagement/patrolStandard/patrolStandardDetails',
                })
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            //删除
            delTableData(id) {
                this.deleteNum = id;
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //调用删除接口
                    this.currentPage = this.delData.delData(this.inspectionStandardList.length, this.pageQuantity,this.currentPage)
                    this.inspectionStandardDelete();
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            //调用删除接口，实现删除
            async inspectionStandardDelete() {
                var param = {
                    ID: this.deleteNum
                }

                const res = await inspectionStandardDelete(param);

                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.inspectionStandardQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            }
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .buttonBorder {
        border: none;
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>